<template>
    <footer>
        <section>
            <app-logo :in-footer="true" />
        </section>
        <section>
            <div class="columns align-items-center col-gapless bordered">
                <div class="column">
                    <base-link
                        href="https://www.envirocentre.ca/"
                    >
                        <img src="@/assets/img/byenviroEN.svg" :title="$root.translate('by envirocentre')" />
                    </base-link>
                </div>
                <!--
                <div class="column">
                    <img src="@/assets/img/logo_OSTA.svg" :title="$root.translate('OSTA - Ottawa Student Transportation Authority')" />
                </div>
                //-->
            </div>
        </section>
        <section>
            <div class="text-small">
                {{ $root.translate("With support from") }}
            </div>
            <div class="text-center py-5">
                <img src="@/assets/img/partners_logos.svg" :title="$root.translate('City of Ottawa, Ottawa Catholic School Board and Ottawa-Carleton District School Board')" />
            </div>
            <!--
            <div class="columns justify-content-center align-items-center py-5">
                <div class="column flex-static pr-4">
                    <img src="@/assets/img/Ottawa_logo.svg" :title="$root.translate('City of Ottawa')" />
                </div>
                <div class="column flex-static px-4">
                    <img src="@/assets/img/OCSB_logo.svg" :title="$root.translate('OCSB - Ottawa Catholic School Board')" />
                </div>
                <div class="column flex-static pl-4">
                    <img src="@/assets/img/OCDSB_logo.svg" :title="$root.translate('OCDSB - Ottawa-Carleton District School Board')" />
                </div>
            </div>
            //-->
            <div class="text-xsmall">
                {{ $root.translate("This project is made possible through financial support from Green Communities Canada and the Government of Ontario") }}
            </div>
            <div class="columns bordered legalese text-xsmall mt-4">
                <div class="column">
                    <router-link
                        :to="{ name: 'page', params: { slug: 'terms' } }"
                    >
                        {{ $root.translate("Terms of use") }}
                    </router-link>
                </div>
                <div class="column">
                    <router-link
                        :to="{ name: 'page', params: { slug: 'privacy' } }"
                    >
                        {{ $root.translate("Privacy Policy") }}
                    </router-link>
                </div>
                <div class="column">
                    <router-link
                        :to="{ name: 'page', params: { slug: 'accessibility' } }"
                    >
                        {{ $root.translate("Accessibility") }}
                    </router-link>
                </div>
            </div>
        </section>
        <section class="text-small">
            &copy; {{ today | nibnut.date("yyyy") }} Studio F<span v-if="!!app_version" class="app-version-indicator"> &bull; {{ app_version }}</span>
        </section>
    </footer>
</template>

<script>
import { profile_utilities, string_utilities } from "@/nibnut/mixins"

import { AppLogo, BaseLink } from "@/nibnut/components"

export default {
    mixins: [profile_utilities, string_utilities],
    components: {
        AppLogo,
        BaseLink
    },
    computed: {
        today () {
            return new Date()
        }
    }
}
</script>
